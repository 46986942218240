import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, map, Observable, of, tap } from 'rxjs';
import { Lang } from '../../../enums/lang.enum';
import { Story } from '../../../interfaces/banner.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { ApiResponse } from '../../../interfaces/response.interface';
import { Confirmation, ConfirmationKey, Profile, UserDevicesResponseDto } from '../../../interfaces/user.interface';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';
import { UserService } from '../services/user.service';
import { UserActions } from './user.actions';


interface UserStateModel {
  profile: Profile;
  confirmations: Confirmation[];
  confirmationsKey: ConfirmationKey;
  userDevices: UserDevicesResponseDto;
  removeDevice: HttpError;
  stories: Story[];
  onboardingCompleted: boolean;
  isStoriesReceived?: boolean;
}


@State<UserStateModel>({
  name: 'user'
})
@Injectable()
export class UserState {

  @Selector()
  public static selectProfile(state: UserStateModel) {
    return state.profile;
  }


  @Selector()
  public static selectConfirmations(state: UserStateModel) {
    return state.confirmations;
  }


  @Selector()
  public static selectConfirmationsKey(state: UserStateModel) {
    return state.confirmationsKey;
  }


  @Selector()
  public static selectUserLanguage(state: UserStateModel): Lang {
    return state.profile.userLocale || Lang.RU;
  }


  @Selector()
  public static selectDevices(state: UserStateModel): UserDevicesResponseDto {
    return state.userDevices;
  }


  @Selector()
  public static selectMbBlocked(state: UserStateModel): boolean {
    return state.profile.mbBlocked;
  }


  @Selector()
  public static selectStories(state: UserStateModel): Story[] {
    return state.stories || [];
  }


  @Selector()
  public static selectOnboardingCompleted(state: UserStateModel): boolean {
    return state.onboardingCompleted;
  }


  @Selector()
  public static selectIsStoriesReceived(state: UserStateModel): boolean {
    return !!state.isStoriesReceived;
  }


  constructor(
    private readonly userService: UserService) {
  }


  @Action(UserActions.GetClient)
  public getClient(ctx: StateContext<UserStateModel>) {
    return this.userService.getClient().pipe(
      tap(profile => ctx.patchState({ profile }))
    );
  }


  @Action(UserActions.UserBlockingPlatform)
  public userBlockingPlatform(ctx: StateContext<UserStateModel>, { requestData }: UserActions.UserBlockingPlatform) {
    const { profile } = ctx.getState();
    const isBlock: boolean = requestData.blocking;

    return this.userService.userBlockingPlatform(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(() => ctx.patchState({
          profile: {
            ...profile,
            mbBlocked: isBlock
          }
        }))
      );
  }


  @Action(UserActions.GetConfirmations)
  public getConfirmations(ctx: StateContext<UserStateModel>) {
    return this.userService.getConfirmations().pipe(
      tap(confirmations => ctx.patchState({ confirmations: confirmations.confirmations }))
    );
  }


  @Action(UserActions.GetConfirmationsKey)
  public getConfirmationsKey(ctx: StateContext<UserStateModel>) {
    return this.userService.getConfirmationsKey().pipe(
      tap(confirmations => ctx.patchState({ confirmationsKey: confirmations }))
    );
  }


  @Action(UserActions.UpdatePhoto)
  public updatePhoto(ctx: StateContext<UserStateModel>, { requestData }: UserActions.UpdatePhoto) {
    return this.userService.updatePhoto(requestData).pipe(
      // tap(profile => ctx.patchState({ profile }))
    );
  }


  @Action(UserActions.UpdateEmail)
  public updateEmail(ctx: StateContext<UserStateModel>, request: UserActions.UpdateEmail) {
    return this.userService.updateEmail(request.requestData).pipe(
      tap(profile => ctx.patchState({ profile }))
    );
  }


  @Action(UserActions.UpdateEripNumber)
  public updateEripNumber(ctx: StateContext<UserStateModel>, { value }: UserActions.UpdateEripNumber) {
    const prof = ctx.getState().profile;
    return this.userService.updateEripNumber(value).pipe(
      tap(profile => ctx.patchState({
        profile: {
          ...prof,
          eripId: value
        }
      }))
    );
  }


  @Action(UserActions.SetLanguage)
  public setLanguage(ctx: StateContext<UserStateModel>, { language }: UserActions.SetLanguage): Observable<ApiResponse> {
    return this.userService.setLanguage(language);
  }


  @Action(UserActions.GetUserDevices)
  public getUserDevices(ctx: StateContext<UserStateModel>): Observable<UserActions.GetUserDevices> {
    return this.userService.getUserDevices().pipe(
      map(userDevices => ctx.patchState({ userDevices }))
    );
  }


  @Action(UserActions.RemoveUserDevice)
  public removeDevice(ctx: StateContext<UserStateModel>, { requestData }: UserActions.RemoveUserDevice) {
    return this.userService.removeDevices(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData)).pipe(
      map(removeDevice => ctx.patchState({ removeDevice }))
    );
  }


  @Action(UserActions.ClearStories)
  public clearStories(ctx: StateContext<UserStateModel>): void {
    ctx.patchState({
      stories: [],
      onboardingCompleted: false,
      isStoriesReceived: false
    });
  }


  @Action(UserActions.GetStories)
  public getStories(ctx: StateContext<UserStateModel>): Observable<UserStateModel> {
    return this.userService.getStories().pipe(
      map(resp => ctx.patchState({
        stories: resp.storyDtos,
        onboardingCompleted: resp.onboardingCompleted
      }))
    );
  }


  @Action(UserActions.CompleteOnboarding)
  public completeOnboarding(ctx: StateContext<UserStateModel>): Observable<UserStateModel> {
    return this.userService.completeOnboarding().pipe(
      map(() => ctx.patchState({ onboardingCompleted: true }))
    );
  }


  @Action(UserActions.StoryViewed)
  public storyViewed(ctx: StateContext<UserStateModel>, { mediaContentId }: UserActions.StoryViewed): Observable<ApiResponse | boolean> {
    const { stories } = ctx.getState();
    const updatedStories: UserStateModel = ctx.patchState({
      stories: stories.map((story: Story) => story.id === mediaContentId
        ? { ...story, isViewed: true }
        : story
      )
    });

    return this.userService.storyViewed(mediaContentId).pipe(
      tap(() => updatedStories),
      catchError(() => of(true)),
      finalize(() => updatedStories)
    );
  }


  @Action(UserActions.SetIsStoriesReceived)
  public setIsStoriesReceived(ctx: StateContext<UserStateModel>): void {
    ctx.patchState({ isStoriesReceived: true });
  }
}
