<div class="input-validator">
  <div class="input-validator-rule"
       [class.valid]="isContainsUpper()"
       [class.invalid]="evaluateConditions() && control.value && !isContainsUpper()"
  >
    <div class="input-validator-rule__title">ABC</div>
    <div class="input-validator-rule__indicator"></div>
  </div>

  <div class="input-validator-rule"
       [class.valid]="isContainsLower()"
       [class.invalid]="evaluateConditions() && control.value && !isContainsLower()"
  >
    <div class="input-validator-rule__title">abc</div>
    <div class="input-validator-rule__indicator"></div>
  </div>

  <div class="input-validator-rule"
       [class.valid]="isContainsNumber()"
       [class.invalid]="evaluateConditions() && control.value && !isContainsNumber()"
  >
    <div class="input-validator-rule__title">123</div>
    <div class="input-validator-rule__indicator"></div>
  </div>
</div>
