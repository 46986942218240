import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StringUtils } from '../../../../../utils/string.utils';


@Component({
  selector: 'app-input-validator',
  templateUrl: './input-validator.component.html',
  styleUrls: [ './input-validator.component.scss' ]
})
export class InputValidatorComponent {

  @Input()
  public control!: FormControl<string>;


  public isContainsUpper(): boolean {
    return this.control?.value?.split('').some((i: string) => /[A-ZА-ЯЁ]/.test(i));
  }


  public isContainsLower(): boolean {
    return this.control?.value?.split('').some((i: string) => /[a-zа-яё]/.test(i));
  }


  public isContainsNumber(): boolean {
    return this.control?.value?.split('').some(i => Number.isInteger(+i));
  }


  public evaluateConditions(): boolean {
    const upper: boolean = this.isContainsUpper();
    const lower: boolean = this.isContainsLower();
    const number: boolean = this.isContainsNumber();

    return upper || lower || number;
  }
}
